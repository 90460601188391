<template>
    <div class="culturalRelicsDetail">
        <div class="container">
            <el-breadcrumb separator-class="el-icon-d-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/cultural-relics' }">文物</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="desc-box">
                <div class="de-left">
                    <img v-if="detail.lowPixelThreeUrl" @click="handleUrl(detail.lowPixelThreeUrl)" class="vr-img cursor-pointer" src="@/assets/imgs/cr-3d.png" alt="">
<!--                    <img :src="detail.cover" alt="" class="desc-img">-->
                    <el-image
                        :src="detail.cover"
                        class="desc-img"
                        :preview-src-list="[detail.cover]">
                    </el-image>
                </div>
                <div class="de-right font-style-s">
                    <div class="name font-style-s">{{detail.title}}</div>
                    <!-- 不展示尺寸 ——cai 2025.1.8 -->
                    <!-- <div class="p-size font-style-s">尺寸：{{detail.dimensions || '-'}}</div> -->
                    <div class="type-wrapper">
                        <div class="type-bar">
                            <div class="line"></div>
                            <div class="type-item ">
                                <div class="dot"></div>
                                <div class="type-name font-style-s">年代</div>
                                <div class="type-value font-style-s">{{detail.historicalEraRangeName || '-'}}</div>
                            </div>
                            <div class="type-item">
                                <div class="dot"></div>
                                <div class="type-name font-style-s">分类</div>
                                <div class="type-value font-style-s">{{detail.classificationName || '-'}}</div>
                            </div>
                            <div class="type-item">
                                <div class="dot"></div>
                                <div class="type-name font-style-s">质地</div>
                                <div class="type-value font-style-s">{{detail.materialsTypeName || '-'}}</div>
                            </div>
                            <div class="type-item">
                                <div class="dot"></div>
                                <div class="type-name">级别</div>
                                <div class="type-value">{{detail.levelName || '-'}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="text-wrapper">
                        <div class="text-title">文物介绍</div>
                        <div class="text-desc"> <p v-html="detail.description"></p> </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const name = '宋酱油堆贴水波纹带盖瓷瓶'
const size = '尺寸：600mm*20mm'


    import { useRoute, useRouter } from 'vue-router/composables'
    import { onMounted, reactive, ref, toRefs } from 'vue'
    import {antiqueDetail} from '@/api/index'
    import moment from 'moment'
    const route = useRoute()
    const query = route.query ||{}

    const state = reactive({
        detail:{}
    })

    const {detail} = toRefs(state)
    console.log(query,'2');

const handleUrl=(url)=>{
    window.open(url,'_blank')
}

    onMounted(() => {
        // 获取详情
        antiqueDetail(query).then(({data})=>{
            console.log(data,999)
            state.detail = data
        })

    })


// 获取文物详情



</script>

<style lang="scss" scoped>
.culturalRelicsDetail{
    padding:44px 0 96px;
    .container{
        width: 1498px;
    }
    ::v-deep{
        .el-breadcrumb__inner.is-link{
            font-weight: normal;
            &:hover{
                color: #8b3527;
            }
        }
    }
    .desc-box{
        display: flex;
        box-sizing: border-box;
        border: 1px solid #c1c1c1;
        width: 100%;
        margin-top: 37px;
        .de-left{
            width: 753px;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background:#e8e8e8;
            padding: 126px 161px 149px;
            box-sizing: border-box;
            position: relative;
            .vr-img{
                position: absolute;
                left: 43px;
                top: 43px;
                width: 89px;
                height: 89px;
            }
            .desc-img{
                width: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        }
        .de-right{
            flex: 1;
            flex-shrink: 0;
            border-left: 1px solid #c1c1c1;
            .name{
                margin-top: 93px;
                font-weight:700;
                color:#3b3b3b;
                font-size:41px;
                text-align: center;
                padding: 0 105px;
            }
            .p-size{
                margin-top: 10.5px;
                font-weight:700;
                color: rgba(59, 59, 59, 0.5);
                font-size:22px;
                text-align: center;
            }
            .type-wrapper{
                margin-top: 90px;
                padding: 0 70px;
            }
            .type-bar{
                display: flex;
                position: relative;
                .line{
                    position: absolute;
                    width: 460px;
                    height: 2px;
                    background: #8b3527;
                    z-index: 1;
                    top: 9px;
                    left: 70px;
                }
                .type-item{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .dot{
                        width:15px;
                        height:15px;
                        background:#fff;
                        border: 2px solid #8b3527;
                        border-radius: 50%;
                        position: relative;
                        z-index: 2;
                    }
                    .type-name{
                        font-weight:700;
                        color: rgba(59, 59, 59, 0.5);
                        font-size:20px;
                        margin-top: 3px;
                    }
                    .type-value{
                        margin-top: 12px;
                        color:#3b3b3b;
                        // 文字竖排
                        writing-mode: vertical-lr;
                        font-weight:700;
                        font-size:30px;
                    }
                }
            }
            .text-wrapper{
                padding: 0 120px;
                margin-top: 100px;
                margin-bottom: 90px;
                .text-title{
                    font-weight:700;
                    color:#3b3b3b;
                    font-size:33px;
                    margin-bottom: 38px;
                }
                .text-desc{
                    color: rgba(28, 28, 28, 0.58);
                    font-size:16px;
                    line-height:36px;

                    ::v-deep img{
                        max-width: 100%;
                    }

                }
            }
        }
    }
}
</style>
