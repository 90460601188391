<script setup>

import { useRoute, useRouter } from 'vue-router/composables'
import { onMounted, reactive, ref, toRefs } from 'vue'
import {getHeritageDetail, spuDetail} from '@/api/index'
import moment from 'moment'
const route = useRoute()
const query = route.query ||{}

const state = reactive({
    detail:{}
})

const {detail} = toRefs(state)
console.log(query,'2');

onMounted(() => {
    // 获取详情
    getHeritageDetail(query).then(({data})=>{
        console.log(data,999)
        state.detail = data
    })

})



</script>

<template>
    <div class="wrap">
        <el-breadcrumb class="wrap-tit" separator-class="el-icon-d-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/intangible-cultural' }">非遗展示</el-breadcrumb-item>
            <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="box">
            <div class="title">{{ detail.title }}</div>
            <el-image class="img" :src="detail.img" mode="aspectFit" :preview-src-list="[detail.img]"/>
            <div class="desc">
                <div>
                    <span class="m-r-10">名称：</span>
                    {{ detail.title }}
                </div>
                <div>
                    <span class="m-r-10">作者：</span>
                    {{ detail.author }}
                </div>
                <div>
                    <span class="m-r-10">版种：</span>
                    {{ detail.type }}
                </div>
                <div>
                    <span class="m-r-10">尺寸：</span>
                    {{ detail.size }}
                </div>
                <div>
                    <span class="m-r-10">年代：</span>
                    {{ detail.age }}
                </div>
                <div>
                    <span class="m-r-10">简介：</span>
                    {{ detail.description }}
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">


.wrap{
    .wrap-tit{
        margin-top: 41px;
        margin-left: 210px;
    }


}
.box {
    width:931px;
    min-height:1555px;
    background:#ffffff;
    border:1px solid #c1c1c1;
    margin:37px auto;
    position: relative;
    padding: 60px 79px;
    box-sizing: border-box;
}

.title {
    font-family: Source Han Serif CN;
    font-weight:700;
    color:#3b3b3b;
    font-size:41px;
    margin-bottom: 116px;
}

.img {
    width: 100% !important;
}

.desc {
    font-family: Source Han Sans CN;
    color: #373737;
    opacity: 0.6;
    font-size:24px;
    position: absolute;
    bottom: 87px;
    left: 84px;
    right: 84px;

    >div{
        margin-bottom: 25px;
    }
}

.m-r-10 {
    margin-right: 10px;
}
</style>
