<script setup>
import { onMounted, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import {getHeritageLists, spuLists} from '../api/index'
import moment from 'moment'

const router = useRouter()


const state = reactive({
    lists:[],
    total:0,
    page:1,
    pageSize:12,
    types: [
        {
            name: '松溪版画',
            id: 1
        }, {
            name: '九龙窑青瓷',
            id: 2
        }, {
            name: '湛卢剑',
            id: 3
        },
    ],
    selectId: 1,
})


// 获取列表
const getLists = ()=>{
    const data={
        pageNo:state.page,
        pageSize:state.pageSize,
        classify: state.selectId
    }
    getHeritageLists(data).then(({data})=>{
        const _data = data?.list || []

        state.lists = _data
        state.total = data.total
        console.log(_data,333);

    })
}

// 分页页数更改的时候
const handleCurrentChange =(val)=>{
    state.page = val
    getLists()
}

const handleTypes =(item)=>{
    state.selectId = item.id
    getLists()
}


const {lists,detail,total,page,types,selectId} = toRefs(state)


onMounted(() => {

    getLists()

})



</script>

<template>
    <div class="wrap">
        <img class="banner" src="@/assets/imgs/Group1408@2x.png" />


        <div class="mid-wrapper">
            <div class="tab">
                <div
                    class="tab-item"
                    :class="{active: selectId === item.id}"
                    v-for="item in types"
                    :key="item.id"
                    @click="handleTypes(item)">{{item.name}}</div>
            </div>
        </div>


        <!--   列表     -->
        <ul>
            <li v-for="item in lists" :key="item.id">
                <router-link :to='"/intangible-cultural/detail?id=" +item.id'>
                    <div class="img-box tc">
                        <img :src="item.img">
                    </div>
                    <p class="tc line-clamp1">{{item.name}}</p>
                </router-link>
            </li>
        </ul>


        <el-pagination
            background
            layout="prev, pager, next"
            class="tc"
            prev-text="上一页"
            next-text="下一页"
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :total="total">
        </el-pagination>

    </div>
</template>

<style scoped lang="scss">
.wrap{
    padding-bottom: 176px;
    ul{
        gap: 43px;
        flex-wrap: wrap;
        padding: 65px 46px;
        display: grid;
        grid-template-columns: repeat(4,1fr);
        margin-bottom: 148px;
        li{
            cursor: pointer;
            background: transparent;

            .img-box{
                width:100%;
                max-width: 419px;
                height:419px;

                img{
                    height: 100%;
                }

            }
            p{
                color:#16161a;
                font-size:24px;
                margin-top: 18px;
                max-width: 419px;
            }
        }
    }
}
.banner{
    width: 100%;
}

.mid-wrapper{
    padding: 64px 0 95px;
    box-sizing: border-box;
    .tab{
        display: flex;
        justify-content: center;
        .tab-item{
            height:40px;
            padding: 0 20px;
            display: inline-block;
            line-height: 40px;
            text-align: center;
            color:#414141;
            font-size:24px;
            cursor: pointer;
            background-image: url("~@/assets/imgs/ex3.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
            & + .tab-item{
                margin-left: 20px;
            }
            &:hover{
                background-image: url("~@/assets/imgs/ex4.png");
                color:#8b3527;
                cursor: pointer;
            }
            &.active{
                background-image: url("~@/assets/imgs/ex2.png");
                color:#fff;
            }

        }
    }
}

</style>
